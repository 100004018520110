@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-family: "Poppins", sans-serif;
  color: zinc;
  --swiper-theme-color: #ff4800 !important;
}
::-webkit-scrollbar {
  display: none;
}
tr {
  background-color: #ff4800;
}

.polygon {
  clip-path: polygon(
    0% 0%,
    0% 100%,
    25% 100%,
    25% 25%,
    75% 25%,
    75% 75%,
    25% 75%,
    25% 100%,
    100% 100%,
    100% 0%
  );
  box-shadow: 2px 2px 2px;
  height: 40vh;
  z-index: -1;
  top: 25vh;
  left: 0;
  width: 40vw;
  background-color: #ff480028;
  position: absolute;
}

/**** HEADER ****/
.social_ico {
  @apply w-8 h-full text-assoCol transition ease-in-out hover:scale-95;
}
.ico {
  @apply w-6 h-6  text-assoCol;
}

.modal-box {
  @apply w-screen;
}

.navbar__button {
  @apply flex flex-col drop-shadow-lg font-bold justify-end items-center cursor-pointer hover:text-white duration-200 px-2  uppercase  hover:scale-105 text-white  lg:flex-row;
}
.navbar__button__mobile {
  @apply flex flex-col p-4   justify-end items-center hover:text-assoCol duration-200 border-b-2 border-assoCol uppercase font-bold hover:scale-105 text-zinc-100  lg:flex-row;
}
/**HOME**/
.backgroundAssistenza {
  background-image: url("https://www.resolve-consulenza.it/wp-content/uploads/2021/11/accesso-remoto.jpg");
}
.backgroundNews {
  background-image: url("https://i.im.ge/2022/07/23/F7ALLP.jpg");
  width: 100vw;
  background-size: contain;
  background-position: center;
}

.backgroundSoluzioni {
  background-image: url("../src/assets/images/securityPic.jpeg");
}

.backgroundEventi {
  background-image: url("https://www.a-marksfactory.it/wp-content/uploads/2017/03/formazione.png");
}
.slide__box {
  @apply flex flex-col justify-center bg-black bg-opacity-50 lg:flex-row;
}

.slide__img {
  @apply w-full h-1/2 lg:w-2/3 lg:h-full;
}

.slide__text {
  @apply w-full h-1/2 flex justify-center items-center p-12 lg:w-1/3 lg:h-full;
}
.th {
  @apply bg-assoNav text-white;
}
.tbody {
  @apply bg-transparent hover:bg-assoNav hover:text-white;
}

.eventiHero {
  background-image: url("https://www.inrca.it/inrca/Files/Comunicazione%20Formazione/1600x450%20Formazione%201667676577mod.png");
}
#app {
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
  z-index: 0;
  z-index: 0;
}

.swiper-wrapper {
  z-index: 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  z-index: 0;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: orangered;
}
.neumorphism {
  border-radius: 65px;
  background: #e0e0e0;
  box-shadow: 14px -14px 28px #cacaca, -14px 14px 28px #f6f6f6;
}
