@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    background-color: #ffff;
    @apply w-screen min-h-screen;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
